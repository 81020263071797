import React from "react"
import { SvgIcon } from "@mui/material"

export default function LineIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.0"
        id="katman_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 475 450"
      >
        <path
          d="M300.1,57.9C398.3,57.9,478,122,478,201c0,27.6-9.7,53.4-26.5,75.2c-0.8,1.2-1.9,2.6-3.3,4.1l-0.1,0.1
          c-5.7,6.9-12.2,13.4-19.3,19.4c-49.2,45.7-130.1,100-140.8,91.7c-9.3-7.3,15.3-43-13.1-48.9c-2-0.2-3.9-0.5-5.9-0.7h0
          c-83.3-11.9-146.6-70.5-146.6-140.9C122.3,121.9,201.9,57.9,300.1,57.9L300.1,57.9z M409.9,159h-35.9c-5.1,0-9.3,4.2-9.3,9.3v69.1
          c0,5.1,4.2,9.3,9.3,9.3h35.9c5.1,0,9.3-4.2,9.3-9.3v-0.8c0-5.1-4.2-9.3-9.3-9.3h-25.8v-14.7h25.8c5.1,0,9.3-4.2,9.3-9.3v-0.8
          c0-5.1-4.2-9.3-9.3-9.3h-25.8v-14.7h25.8c5.1,0,9.3-4.2,9.3-9.3v-0.8C419.2,163.2,415,159,409.9,159z M197.7,158.9h-0.8
          c-5.1,0-9.3,4.2-9.3,9.3v69.1c0,5.1,4.2,9.3,9.3,9.3h35.9c5.1,0,9.3-4.2,9.3-9.3v-0.8c0-5.1-4.2-9.3-9.3-9.3H207v-59
          C207,163,202.8,158.9,197.7,158.9z M292,158.9h-0.8c-5.1,0-9.3,4.2-9.3,9.3v69.1c0,5.1,4.2,9.3,9.3,9.3h0.8c5.1,0,9.3-4.2,9.3-9.3
          V196l33.8,46.3l0.3,0.5l0.4,0.5c0.9,1.3,2.2,2.1,3.6,2.7c1.1,0.4,2.3,0.7,3.5,0.7h0.8c1.5,0,3-0.4,4.3-1.1c0.6-0.3,1.2-0.6,1.6-1.1
          l0.6-0.6c1.7-1.8,2.7-4.1,2.7-6.5v-69.1c0-5.1-4.2-9.3-9.3-9.3h-0.8c-5.1,0-9.3,4.2-9.3,9.3v40.5l-33.5-45.2
          C298.5,160.8,295.4,158.9,292,158.9z M259.6,158.9h-0.8c-5.1,0-9.3,4.2-9.3,9.3v69.1c0,5.1,4.2,9.3,9.3,9.3h0.8
          c5.1,0,9.3-4.2,9.3-9.3v-69.1C268.9,163.1,264.7,158.9,259.6,158.9L259.6,158.9z"
        />
      </svg>
    </SvgIcon>
  )
}
